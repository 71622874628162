import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../organisms/layout.organism"
import Post from "../organisms/post.organism"
import { Container } from "../atoms/container.atom"
import theme from "../../theme"
import { convertToID } from "../util"


const StyledContainer = styled(Container)`
  ul {
    margin-bottom: 80px;
    list-style-type: inherit;
    padding-left: 20px;
    li {
      line-height: 2;
    }
  }
  ${theme.media.tablet`
    padding-top: 100px;
  `}
`


export default function PageTemplate(props) {
  // console.log(props.pageContext.frontmatter.headline)
  const frontmatter = props.pageContext.frontmatter
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.headline} ∙ Bloomista</title>
        <meta name="description" content={frontmatter.description} />
        <link rel="canonical" href={"https://bloomista.garden/" + convertToID(frontmatter.headline)} />
      </Helmet>
      <StyledContainer>
        <h1>{frontmatter.headline}</h1>
        {props.children}
      </StyledContainer>
    </Layout>
  )
}